import { useState, useLayoutEffect } from "react";

export function useIsInView(
  elementRef: React.RefObject<Element>,
  { threshold = 0, root = null, rootMargin = "0px" }: IntersectionObserverInit,
) {
  const [intersectionEntry, setIntersectionEntry] = useState<IntersectionObserverEntry>();

  const updateEntry = ([callbackEntry]: IntersectionObserverEntry[]): void => {
    setIntersectionEntry(callbackEntry);
  };

  useLayoutEffect(() => {
    const node = elementRef?.current;

    if (!node) {
      return;
    }

    const observer = new IntersectionObserver(updateEntry, {
      threshold,
      root,
      rootMargin,
    });

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [elementRef, rootMargin]);

  return { intersectionEntry };
}
